<template>
  <div></div>
</template>

<script>
import { shortenLinkService } from '@/services';
import { APP_ROUTES } from '@/core/constants';

export default {
  props: {
    caseReference: {
      require: false,
      default: null,
    },
  },
  created() {
    this.getCaseredirect(this.caseReference);
  },
  methods: {
    async getCaseredirect(caseReference) {
      if (caseReference) {
        const res = await shortenLinkService.getRedirectLink(caseReference);
        if (res.err) {
          this.$router.push(APP_ROUTES.ERROR_NOT_FOUND);
        } else {
          // eslint-disable-next-line
          this.$router.push(res.data.replace(/^.*\/\/[^\/]+/, ''));
        }
      }
    },
  },
};
</script>

<style></style>
